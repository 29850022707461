type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };

export const capitalize = <T extends string>(s?: T) =>
  (s ? s[0].toUpperCase() + s.slice(1) : "") as Capitalize<T>;

export const decapitalize = <T extends string>(s?: T) =>
  s?.toLowerCase() as Lowercase<T>;

export const getEntries = <T extends {}, K extends keyof T>(
  obj?: T,
): Array<[K, T[K]]> => {
  return Object.entries(obj ?? {}) as unknown as Array<[K, T[K]]>;
};

export const isError = (e: unknown): e is Error =>
  !!e && Reflect.has(e, "message");

export const isNumStr = (num?: string): num is `${number}` => {
  if (!num) {
    return false;
  }

  const val = Number(num);
  return Number.isFinite(val);
};

/** @description used to resolve type conflicts around __readonly__ types */
export const markWritable = <T>(data: T): DeepWriteable<T> => data;

export const parseNumStr = (num: string) => {
  if (!isNumStr(num)) {
    return `0` as `${number}`;
  }

  return num;
};

export const sleep = <T = unknown>(ms: number) =>
  new Promise<T>((resolve) => setTimeout(resolve, ms));

export const noop = () => undefined;

export const applyMapWith =
  <T, R>(mapperFn: (item: T) => R) =>
  (list?: T[]) =>
    list?.map(mapperFn);
