import styled from "styled-components";

export const NotificationComponent = styled.div`
  background: var(--fill-primary);
  border-radius: 24px;
  position: fixed;
  padding: 12px 24px 12px 14px;
  width: calc(100vw - 32px);
  left: 50%;
  transform: translateX(-50%);
  top: 24px;
  z-index: 1560;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  gap: 16px;
  box-sizing: border-box;
  min-height: 88px;
  max-width: 464px;
  text-align: left;
  .notification {
    &__icon {
      background: var(--fill-secondary);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      width: 64px;
      height: 64px;
      flex-shrink: 0;
      img {
        filter: invert(var(--svg-invert));
      }
    }
  }
`;
