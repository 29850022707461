import type { BgType } from "@/types/common";

// todo split file semantically

/** @deprecated ? */
export const pathnameToBgType = (pathname?: string): BgType | undefined => {
  if (pathname == "/") {
    return "home";
  }
};

/** @deprecated ? */
export const pathnameToPrevRoute = (pathname?: string, previous?: string) => {
  if (
    pathname?.includes("watch-position") ||
    pathname?.includes("lend-info") ||
    pathname?.includes("earn")
  ) {
    return "/";
  }

  return previous;
};

/** @deprecated */
export const getNetworkIcon = (networkId: number | undefined) => {
  const path = "/images/network";
  switch (networkId) {
    case 1:
      return `${path}/ethereum.svg`;
    case 421613:
      return `${path}/arbitrum.svg`;
    case 80001:
      return `${path}/polygon.svg`;
    case 280:
      return `${path}/zksync.svg`;
    default:
      return "/images/token/arb.svg";
  }
};
