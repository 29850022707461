import BigNumber from "bignumber.js";
BigNumber.config({ EXPONENTIAL_AT: 1e9 });

export const ZERO = new BigNumber(0);
export const ONE = new BigNumber(1);
export const TWO = new BigNumber(2);
export const TEN = new BigNumber(10);

export const TWO_POW96 = TWO.pow(96);
export const TWO_POW48 = TWO.pow(48);

export const E18 = TEN.pow(18);
export const INV_E18 = TEN.pow(-18);

/** @description BigInt(2^256) */
export const MAX_128 = BigInt("340282366920938463463374607431768211456");

/** @deprecated prefer explicit vars */
export const REALLY_BIGINT = MAX_128;

/** @description BigInt(2^256 - 1) */
export const MAX_256 = BigInt(
  "115792089237316195423570985008687907853269984665640564039457584007913129639935",
);
