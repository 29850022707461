/** @deprecated causes unhandled rejection exceprion; that is logged by sentry; !!!IMPORTANT if using it in useEffect callback wrap it in some function for easier stack traces  */
export const _debug = ({
  error,
  message,
  cause,
}: {
  error?: Error;
  message?: string;
  cause?: Error;
}) => {
  const _error =
    error ??
    new Error(message ? `debug: ${message}` : cause?.message, {
      cause,
    });

  setTimeout(() => {
    Promise.reject(_error);
  });
};
