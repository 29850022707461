"use client";
import Noop from "@marginly/ui/components/helpers/noop";
import T from "@marginly/ui/components/typography";
import { useServerInsertedHTML } from "next/navigation";
import React from "react";
import { useEffect, useRef } from "react";
import { ServerStyleSheet, StyleSheetManager } from "styled-components";

import { UTM_CACHE } from "@/constants/common";
import { getUtmFromSearchParams } from "@/util/tracking";

export const StyledComponentsRegistry: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [stylesheet] = React.useState(() => new ServerStyleSheet());

  useServerInsertedHTML(() => {
    const styles = stylesheet.getStyleElement();
    stylesheet.instance.clearTag();
    return <>{styles}</>;
  });

  if (typeof window !== "undefined") return <>{children}</>;

  return (
    <StyleSheetManager sheet={stylesheet.instance}>
      {children}
    </StyleSheetManager>
  );
};

export { T };

export default function ClientHandler() {
  const ready = useRef(false);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    if (!ready.current) {
      const utm = getUtmFromSearchParams(
        new URLSearchParams(window.location.search),
      );

      if (utm) {
        localStorage.setItem(UTM_CACHE, JSON.stringify(utm));
      }
    }

    ready.current = true;
  }, []);
  return <Noop />;
}
