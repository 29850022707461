"use client";

import { useCallback, useEffect, useRef, useState } from "react";
import { useAccount } from "wagmi";

import { request } from "@/util/request";

import { MemberInfoContext } from "./context";

interface Member {
  refCode: null;
  refLink: null;
}

const getUserExists = async (contestNumber: number, memberAddress: string) => {
  return request<Member>(`contest/${contestNumber}/member/${memberAddress}`);
};

export function MemberInfoProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { address } = useAccount();
  const [refCode, setRefCode] = useState<string | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  const mounted = useRef<boolean>();

  const update = useCallback(async () => {
    if (!address) {
      setRefCode(null);
      setLoaded(false);
      return;
    }
    const response = await getUserExists(1, address);
    if (mounted.current) {
      setRefCode(response.refCode);
      setLoaded(true);
    }
  }, [address]);

  useEffect(() => {
    mounted.current = true;
    update();
    return () => {
      mounted.current = false;
    };
  }, [update]);

  return (
    <MemberInfoContext.Provider value={{ refCode, loaded, refetch: update }}>
      {children}
    </MemberInfoContext.Provider>
  );
}
