"use client";

import React, { useMemo, useState } from "react";
import type { Chain } from "viem";

import { MemberInfoProvider } from "@/contexts/contest-member-info/provider";
import { onWalletConnect, onWalletDisconnect } from "@/lp/actions/wallet";
import { Web3Provider } from "@/lp/context/web3";
import type { PoolConfig } from "@/lp/types/core";
import { FIX_CHAIN_ID, patchChain } from "@/lp/util/viem-ext";

interface ProviderProps {
  serverTime: number;
  chain: Chain;
  children: React.ReactNode;
  projectId: string;
}
export default function Provider({
  children,
  chain: _chain,
  serverTime: _serverTime,
  projectId,
}: ProviderProps) {
  // const theme = useThemeConfigStore();

  const chains = useMemo(
    () => [FIX_CHAIN_ID.includes(_chain.id) ? patchChain(_chain) : _chain],
    [_chain],
  );

  return (
    <Web3Provider
      projectId={projectId}
      chains={chains}
      onWalletConnect={onWalletConnect}
      onWalletDisconnect={onWalletDisconnect}
    >
      <MemberInfoProvider>{children}</MemberInfoProvider>
    </Web3Provider>
  );
}
