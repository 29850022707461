import { BehaviorSubject } from "rxjs";

export const createBoundBehaviorSubject = <T>(
  _initialValue: T,
  storageKey: string,
) => {
  if (typeof window === "undefined") {
    const subject = new BehaviorSubject(_initialValue);

    return {
      subject,
      push: subject.next.bind(subject),
    };
  }

  const ls = window?.localStorage;

  if (!ls) {
    throw new Error("localStorage is not available");
  }

  const initialValue: T =
    JSON.parse(ls.getItem(storageKey) || "null") ?? _initialValue;

  const subject = new BehaviorSubject(initialValue);

  const push = (value: T) => {
    ls.setItem(storageKey, JSON.stringify(value));
    subject.next(value);
  };

  return {
    subject,
    push,
  };
};
