type Environment = "stage" | "prod";
const urlByEnv: Record<Environment, string> = {
  stage: "https://api-stage.marginly.com/api",
  prod: "https://api.marginly.com/api",
};

export const request = async <TResponse>(
  url: string,
  config: RequestInit = {},
  environment: Environment = "prod",
): Promise<TResponse> => {
  const response = await fetch(`${urlByEnv[environment]}/${url}`, {
    ...config,
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      ...config.headers,
    },
  });
  const res = await response.json();
  if (response.ok) {
    return res;
  }
  throw new Error(res);
};
