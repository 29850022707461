import { useEffect, useRef, useState } from "react";
import { BehaviorSubject } from "rxjs";

export const useSubjectFrom = <T>(v: T) => {
  const initial = useRef(true);
  const [subject$] = useState(() => new BehaviorSubject(v));

  useEffect(() => {
    if (initial.current) {
      initial.current = false;
    } else {
      subject$.next(v);
    }
  }, [subject$, v]);

  return subject$.asObservable();
};
