import { createContext } from "use-context-selector";

interface MemberInfoContextModel {
  refCode: string | null;
  loaded: boolean;
  refetch: () => void;
}

export const MemberInfoContext = createContext<MemberInfoContextModel>(
  {} as MemberInfoContextModel,
);
