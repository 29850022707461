"use client";

import cn from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { useSwipeable } from "react-swipeable";
import styled, { keyframes } from "styled-components";
import {
  CallExecutionError,
  ContractFunctionExecutionError,
  ContractFunctionRevertedError,
  ExecutionRevertedError,
} from "viem";

import {
  NOTIFICATION_VISIBLE_MS,
  SWIPEABLE_CONFIG,
} from "../../constants/common";
import { useRx } from "../../contexts/rx";
import { Notification } from "../../types/common";
import { hasKey } from "../../util/check";
import { getEntries } from "../../util/core";
import { _debug } from "../../util/debug";
import { isWithExactMessage, isWithHiddenProp } from "../../util/error";
import { Noop } from "../util";
import { NotificationComponent } from "./notification";

const showNotification = keyframes`
  0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0%);
	}
`;

const hideNotification = keyframes`
  0% {
		transform: translateY(0%);
	}
	100% {
		transform: translateY(-100%);
	}
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10000;

  .item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 200px;
    overflow: hidden;
    animation: ${hideNotification} 300ms ease-out;
    animation-fill-mode: forwards;
    transform: translateY(-100%);

    &.visible {
      animation: ${showNotification} 300ms ease-out;
      animation-fill-mode: forwards;
      transform: translateY(0%);
    }

    .content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 200px;
      background: rgba(255, 75, 75, 0.75);
      display: flex;
      flex-flow: column nowrap;
      overflow: auto;
    }
  }
`;

export const ErrorMessage = styled.p`
  word-wrap: break-word;
  width: 100%;
  margin: auto 0;
  color: var(--text-invert-primary);
  font-size: 14px;
  font-variation-settings: 700;
  text-align: left;
  text-shadow: 0 0 0;
`;

const ErrorDescription = styled.p`
  word-wrap: break-word;
  width: 100%;
  color: var(--text-invert-primary);
  font-size: 12px;
  text-align: left;
`;

const getUserErrorMessage = (error: Error) => {
  const { message } = error;

  if (!message) return message;

  if (
    ["user", "rejected"].every((word) => message.toLowerCase().includes(word))
  ) {
    return "User rejected request";
  }

  if (
    ["transaction", "underpriced"].every((word) =>
      message.toLowerCase().includes(word),
    )
  ) {
    return "Transaction underpriced. Please try to enlarge gas limit or reset account nonce";
  }

  if (
    ["missing", "revert", "data"].every((word) =>
      message.toLowerCase().includes(word),
    )
  ) {
    return "Transaction failed. Please try to enlarge gas limit or reset account nonce";
  }

  return error instanceof ContractFunctionExecutionError
    ? `Contract execution error:`
    : `Error occurred.`;
};

const getReasonDescription = (reason?: string) => {
  switch (reason) {
    case "STF":
      return `Not enough funds in pool.`;
    case "ZA": // zero amount
      return `You cannot open position with zero amount.`;
    case "MC": // margin call
      return `Your margin will drop below the critical threshold.`;
    case "MA": // minimum amount
      return `You cannot open position with amount less than minimal amount.`;
    case "EL": // exceed limit
      return `The protocol has reached the global limit on the asset. Actions which increase asset balance are not allowed`;
    case "SL": // slippage
      return `Your trade would result in more than 2% slippage. Please wait until the  external AMM liquidity conditions improve and try again.`;
    case "U": // uninitialized
      return `Position in uninitialized state`;
    case "L": // lend position
      return `You cannot close lend position`;
    default:
      console.warn(`Unknown revert reason: ${reason}`);
  }

  return `Please try again later. (${reason})`;
};

const ErrorNotification = ({
  error,
  swiped,
  onClose,
}: {
  error: Error;
  swiped?: boolean;
  onClose?: () => void;
}) => {
  const [desc, setDesc] = React.useState("");

  const [visible, setVisible] = React.useState(
    isWithHiddenProp(error) ? !error.hidden : true,
  );

  useEffect(() => {
    // TODO refactor
    if (error instanceof ContractFunctionExecutionError) {
      const { cause } = error;

      if (cause instanceof ContractFunctionRevertedError) {
        const { reason } = cause;
        setDesc(getReasonDescription(reason));
      } else {
        console.error("unknown cause", { cause });
      }
    } else if (error instanceof CallExecutionError) {
      const { cause } = error;

      if (cause instanceof ExecutionRevertedError) {
        const [, _reason] = cause.details?.split(":");
        const reason = _reason?.trim();
        setDesc(getReasonDescription(reason));
      } else {
        console.error("unknown cause", { cause });
      }
    } else {
      const { cause } = error;

      if (hasKey("description", cause)) {
        setDesc(cause.description);
      } else if (hasKey("detail", cause)) {
        setDesc(cause.detail);
      } else {
        console.error("unknown error", { error, cause });
      }
    }

    if (error) {
      _debug({ error });
    }
  }, [error]);

  useEffect(() => {
    const to = setTimeout(() => {
      setVisible(false);
    }, 10000);

    return () => {
      clearTimeout(to);
    };
  }, [error]);

  useEffect(() => {
    if (swiped) {
      setVisible(false);
    }
  }, [swiped]);

  useEffect(() => {
    if (!visible) {
      onClose?.();
    }
  }, [visible, onClose]);

  return (
    <div className={cn("item", { visible })} onClick={() => setVisible(false)}>
      <NotificationComponent>
        <div className="notification__icon">
          <img
            src={
              (isWithExactMessage(error) && error.imgSrc
                ? error.imgSrc
                : undefined) ?? "/images/tip.svg"
            }
            alt=""
          />
        </div>
        <div>
          <ErrorMessage>
            {isWithExactMessage(error)
              ? error.message
              : getUserErrorMessage(error)}
          </ErrorMessage>
          {desc?.length > 0 && <ErrorDescription>{desc}</ErrorDescription>}
        </div>
      </NotificationComponent>
    </div>
  );
};

const MessageNotification = ({
  notification,
  swiped,
}: {
  notification: Notification;
  swiped?: boolean;
}) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setVisible(true);
    const to = setTimeout(() => {
      setVisible(false);
    }, NOTIFICATION_VISIBLE_MS);
    return () => {
      clearTimeout(to);
    };
  }, [notification]);

  useEffect(() => {
    if (swiped) {
      setVisible(false);
    }
  }, [swiped]);

  return (
    <div className={cn("item", { visible })} onClick={() => setVisible(false)}>
      <NotificationComponent>
        <div className="notification__icon">
          <img src={notification.imgSrc ?? "/images/tip.svg"} alt="" />
        </div>
        <div>
          <ErrorMessage>{notification.message}</ErrorMessage>
        </div>
      </NotificationComponent>
    </div>
  );
};

const Errors = () => {
  const [error, setError] = useState<Error>();
  // fixme dirty hack
  const [errors, setErrors] = useState<Record<string, Error | undefined>>({});

  const [notification, setNotification] = useState<Notification>();
  const [swipedNotification, setSwipedNotification] = useState(false);

  const { error$, notification$ } = useRx();

  useEffect(() => {
    const sub = error$.subscribe((nextError) => {
      setSwipedNotification(false);
      setError(nextError);
    });

    return () => {
      sub.unsubscribe();
    };
  }, [error$]);

  useEffect(() => {
    const sub = notification$.subscribe((nextNotification) => {
      setSwipedNotification(false);
      setNotification(nextNotification);
    });

    return () => {
      sub.unsubscribe();
    };
  }, [notification$]);

  const handlers = useSwipeable({
    onSwipedUp: () => setSwipedNotification(true),
    ...SWIPEABLE_CONFIG,
  });

  // fixme dirty hack
  useEffect(() => {
    if (error) {
      const id = `${Math.random()}`;
      setErrors((prev) => ({ ...prev, [id]: error }));
    }
  }, [error]);

  function onClose(id: string) {
    setTimeout(() => {
      setErrors((errors) => ({ ...errors, [id]: undefined }));
    }, 5000);
  }

  return (
    <Wrapper {...handlers}>
      {getEntries(errors)
        .filter(([, e]) => !!e)
        .map(([id, error]) => {
          if (!error || (isWithHiddenProp(error) && error.hidden)) {
            return <Noop key={id} />;
          }

          return (
            <ErrorNotification
              key={id}
              error={error}
              swiped={swipedNotification}
              onClose={onClose.bind(null, id)}
            />
          );
        })}
      {!!notification && (
        <MessageNotification
          notification={notification}
          swiped={swipedNotification}
        />
      )}
    </Wrapper>
  );
};

export default Errors;
