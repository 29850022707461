"use client";

import { useThemeConfigStore } from "@marginly/ui/stores/theme";
import { jsonRpcProvider } from "@wagmi/core/providers/jsonRpc";
import type { ThemeController } from "@web3modal/core";
import { EIP6963Connector } from "@web3modal/wagmi";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { useEffect, useMemo, useRef, useState } from "react";
import type { Chain } from "viem/chains";
import { configureChains, createConfig, useAccount, WagmiConfig } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

import logo from "@/components/common/login-header/navigation/logo.svg";
import logoWhite from "@/components/navigation/images/logo-dark.svg";
import { onWalletConnect, onWalletDisconnect } from "@/lp/actions/wallet";
import { generateUUID } from "@/lp/util/fallback";
if (typeof window !== "undefined") {
  // required fallback for walletconnect initialize on old devices
  if (
    typeof crypto !== "undefined" &&
    typeof crypto.randomUUID !== "function"
  ) {
    crypto.randomUUID = generateUUID;
  }
}

type ThemeVars = (typeof ThemeController)["state"]["themeVariables"];

const internalRpcs: Record<number, string[]> = {
  421613: ["https://scn.equilab.io/arb/goerli-nitro/rpc"],
  80001: ["https://scn.equilab.io/pol/mumbai/rpc"],
};

interface Web3ProviderProps {
  projectId: string;
  children?: React.ReactNode;
  onWalletConnect?: typeof onWalletConnect;
  onWalletDisconnect?: typeof onWalletDisconnect;
  chains: Chain[];
}

export function Web3Provider({
  projectId,
  children,
  chains,
}: Web3ProviderProps) {
  const w3mRef = useRef<ReturnType<typeof createWeb3Modal> | null>(null);

  const themeConfig = useThemeConfigStore();
  const themeMode: ReturnType<typeof useThemeConfigStore> = "light";
  // isThemeVariant(themeConfig)
  //   ? themeConfig
  //   : getSystemTheme();

  const W3M_THEME: ThemeVars = useMemo(
    () => ({
      "--w3m-accent-color": "var(--fill-secondary)",
      "--w3m-logo-image-url": logo.src,
      "--w3m-background-color": "var(--background-primary)",
      "--w3m-color-fg-1": "var(--text-primary)",
      "--w3m-z-index": 1502,
    }),
    [],
  );

  const counter = useRef(0);

  const [provider] = useState(() => [
    jsonRpcProvider({
      rpc: (chain) => {
        const rpcs =
          chain.id in internalRpcs
            ? internalRpcs[chain.id]
            : chain.rpcUrls.default.http;

        return {
          http: rpcs[0],
        };
      },
    }),
    jsonRpcProvider({
      rpc: (chain) => {
        const rpcs = chain.rpcUrls.default.http;
        const index = counter.current++ % rpcs.length;

        return {
          http: chain.rpcUrls.default.http[index],
        };
      },
    }),
  ]);

  const [{ publicClient }] = useState(() =>
    configureChains(chains, provider, { retryCount: 1 }),
  );

  const [wagmiConfig] = useState(() =>
    createConfig({
      autoConnect: true,

      connectors: [
        new WalletConnectConnector({
          chains,
          options: { projectId, showQrModal: false },
        }),
        new EIP6963Connector({ chains }),
        new InjectedConnector({ chains, options: { shimDisconnect: true } }),
      ],

      publicClient,
    }),
  );

  if (!w3mRef.current) {
    w3mRef.current = createWeb3Modal({ wagmiConfig, projectId, chains });
    w3mRef.current.setThemeVariables(W3M_THEME);
  }

  useEffect(() => {
    w3mRef.current?.setThemeMode(themeMode);
  }, [themeMode]);

  return (
    <>
      <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>
    </>
  );
}
