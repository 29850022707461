"use client";
import {
  createContext,
  type FC,
  type ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";
import { BehaviorSubject, Observable, of, ReplaySubject } from "rxjs";

import { Notification } from "@/types/common";
import { createBoundBehaviorSubject } from "@/util/rx/bound-subject";

import { globalUiDefault, useGlobalUi } from "./ui";

interface SavedPosition {
  updated: number;
  basePrice?: string;
  direction?: "long" | "short";
  amount?: string;
}

type Positions = Record<string, SavedPosition>;

interface RxContextVals extends ReturnType<typeof useGlobalUi> {
  error$: ReplaySubject<Error>;
  notification$: ReplaySubject<Notification>;
  showWallet$: BehaviorSubject<boolean>;
  positions$: Observable<Positions>;
  setPosition: (
    key: string,
    value: Omit<SavedPosition, "updated">,
    reset?: boolean,
  ) => void;
}

const initialValues: RxContextVals = {
  ...globalUiDefault,
  showWallet$: new BehaviorSubject(false),
  error$: new ReplaySubject(1),
  notification$: new ReplaySubject(1),
  positions$: of({}),
  setPosition: () => {},
};

const RxContext = createContext(initialValues);

export const RxProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [showWallet$] = useState(() => new BehaviorSubject(false));

  const [error$] = useState(
    () => new ReplaySubject<Error>(1 /** num of errors to display */),
  );

  const globalUi = useGlobalUi();

  const positions = useMemo(() => {
    const { subject, push } = createBoundBehaviorSubject({}, "saved-positions");

    return {
      positions$: subject.asObservable(),
      setPosition: (
        key: string,
        value: Omit<SavedPosition, "updated">,
        reset: boolean = false,
      ) => {
        const prev = subject.getValue();
        if (reset) {
          push({ ...prev, [key]: { updated: 0 } });
        } else {
          push({ ...prev, [key]: { ...value, updated: Date.now() } });
        }
      },
    };
  }, []);

  return (
    <RxContext.Provider
      value={{
        ...globalUi,
        ...positions,
        error$,
        showWallet$,

        notification$: initialValues.notification$,
      }}
    >
      {children}
    </RxContext.Provider>
  );
};

export const useRx = () => useContext(RxContext);
